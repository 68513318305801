<template>
  <div>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Comunidades / Cidades Autónomas') }}
    </h6>
    <v-select
      v-model="localization.comunidade"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="comunidades"
      append-to-body
      :calculate-position="withPopper"
      :multiple="true"
      :loading="loader.comunidade"
      class="mb-1 ml-2 mr-2"
      label="desc"
      item-text="desc"
      item-value="id"
      @input="getProvinciasByComunidade"
      @change="getProvinciasByComunidade"
    >
      <template #option="{ desc }">
        {{ desc }}
      </template>
      <div slot="no-options">
        {{ $t('Nenhuma comunidade / cidade autónoma') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Províncias') }}
    </h6>
    <v-select
      v-model="localization.provincia"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="provinciasGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.provincia"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
      @input="getComarcaByProvincia"
      @change="getComarcaByProvincia"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Províncias de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.comunidade"
        slot="no-options"
      >
        {{ $t('Nenhuma província') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Comarcas') }}
    </h6>
    <v-select
      v-model="localization.comarca"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="comarcasGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.comarca"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
      @input="getMunicipiosByComarca"
      @change="getMunicipiosByComarca"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Comarcas de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.provincia"
        slot="no-options"
      >
        {{ $t('Nenhuma comarca') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Municípios') }}
    </h6>
    <v-select
      v-model="localization.municipio"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="municipiosGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.municipio"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
      @input="getZonasByMunicipio"
      @change="getZonasByMunicipio"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Municípios de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.comarca"
        slot="no-options"
      >
        {{ $t('Nenhum município') }}
      </div>
    </v-select>

    <h6 class="section-label mt-3 mb-1 px-2">
      {{ $t('Zonas') }}
    </h6>
    <v-select
      v-model="localization.zona"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :options="zonasGroup"
      append-to-body
      :calculate-position="withPopperGroup"
      :multiple="true"
      :loading="loader.zona"
      class="mb-1 ml-2 mr-2"
      label="descFull"
      item-text="descFull"
      item-value="id"
      :selectable="option => option.group === null"
    >
      <template #option="{ group, desc }">
        <div
          v-if="group"
          class="group"
        >
          {{ $t('Zonas de') }} {{ group }}
        </div>
        {{ desc }}
      </template>
      <div
        v-if="localization.municipio"
        slot="no-options"
      >
        {{ $t('Nenhuma zona') }}
      </div>
    </v-select>

  </div>
</template>

<script>
import store from '@/store'
import vSelect from 'vue-select'
import { onUnmounted } from '@vue/composition-api'
import localizationModule from '@store-modules/localization/es'
import { mapGetters } from 'vuex'
import { showMsgRequest, eventsCustomSelects } from '@core-custom/mixins/geral'

export default {
  components: {
    vSelect,
  },
  mixins: [showMsgRequest, eventsCustomSelects],
  data() {
    return {
      loader: {
        comunidade: false,
        provincia: false,
        comarca: false,
        municipio: false,
        zona: false,
      },
      localization: {
        comunidade: [],
        provincia: [],
        comarca: [],
        municipio: [],
        zona: [],
      },
    }
  },
  computed: {
    ...mapGetters('localizationMultiple', ['comunidades', 'provincias', 'comarcas', 'municipios', 'zonas']),
    provinciasGroup: {
      get() {
        const options = []

        try {
          this.provincias.forEach(o => {
            if (o.level1) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = o.level1
              options.push(newOption)
            }

            if (o.level2.length > 0) {
              o.level2.forEach(oLevel => {
                const newSubOption = {}
                newSubOption.id = oLevel.id
                newSubOption.desc = oLevel.desc
                newSubOption.descFull = `${oLevel.desc}, ${o.level1}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
        //
        }

        return options
      },
    },
    comarcasGroup: {
      get() {
        const options = []

        try {
          this.comarcas.forEach(o => {
            if (o.level2) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = `${o.level2}, ${o.level1}`
              options.push(newOption)
            }

            if (o.level3.length > 0) {
              o.level3.forEach(oLevel => {
                const newSubOption = {}
                newSubOption.id = oLevel.id
                newSubOption.desc = oLevel.desc
                newSubOption.descFull = `${oLevel.desc}, ${o.level2}, ${o.level1}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
          //
        }

        return options
      },
    },
    municipiosGroup: {
      get() {
        const options = []

        try {
          this.municipios.forEach(o => {
            if (o.level3) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = `${o.level3}, ${o.level2}, ${o.level1}`
              options.push(newOption)
            }

            if (o.level4.length > 0) {
              o.level4.forEach(oLevel => {
                const newSubOption = {}
                newSubOption.id = oLevel.id
                newSubOption.desc = oLevel.desc
                newSubOption.descFull = `${oLevel.desc}, ${o.level3}, ${o.level2}, ${o.level1}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
          //
        }

        return options
      },
    },
    zonasGroup: {
      get() {
        const options = []

        try {
          this.zonas.forEach(o => {
            if (o.level4) {
              const newOption = {}
              newOption.id = null
              newOption.desc = null
              newOption.descFull = null
              newOption.group = `${o.level4}, ${o.level3}, ${o.level2}, ${o.level1}`
              options.push(newOption)
            }

            if (o.level5.length > 0) {
              o.level5.forEach(oLevel => {
                const newSubOption = {}
                newSubOption.id = oLevel.id
                newSubOption.desc = oLevel.desc
                newSubOption.descFull = `${oLevel.desc}, ${o.level4}, ${o.level3}, ${o.level2}, ${o.level1}`
                newSubOption.group = null
                options.push(newSubOption)
              })
            }
          })
        } catch (err) {
          //
        }

        return options
      },
    },
    viewData() {
      const txtDefault = '-----'
      let txtComunidades = [txtDefault]
      let txtProvincias = [txtDefault]
      let txtComarcas = [txtDefault]
      let txtMunicipios = [txtDefault]
      let txtZonas = [txtDefault]

      try {
        if ((this.localization.comunidade !== null) && (this.localization.comunidade.length > 0)) {
          if ((this.localization !== null) && ('comunidade' in this.localization)) {
            txtComunidades = []
            this.localization.comunidade.forEach(row => {
              txtComunidades.push(row.desc)
            })
          }
        }

        if ((this.localization.provincia !== null) && (this.localization.provincia.length > 0)) {
          if ((this.localization !== null) && ('provincia' in this.localization)) {
            txtProvincias = []
            this.localization.provincia.forEach(row => {
              txtProvincias.push(row.descFull)
            })
          }
        }

        if ((this.localization.comarca !== null) && (this.localization.comarca.length > 0)) {
          if ((this.localization !== null) && ('comarca' in this.localization)) {
            txtComarcas = []
            this.localization.comarca.forEach(row => {
              txtComarcas.push(row.descFull)
            })
          }
        }

        if ((this.localization.municipio !== null) && (this.localization.municipio.length > 0)) {
          if ((this.localization !== null) && ('municipio' in this.localization)) {
            txtMunicipios = []
            this.localization.municipio.forEach(row => {
              txtMunicipios.push(row.descFull)
            })
          }
        }

        if ((this.localization.zona !== null) && (this.localization.zona.length > 0)) {
          if ((this.localization !== null) && ('zona' in this.localization)) {
            txtZonas = []
            this.localization.zona.forEach(row => {
              txtZonas.push(row.descFull)
            })
          }
        }
      } catch (err) {
        //
      }

      return {
        comunidades: txtComunidades,
        provincias: txtProvincias,
        comarcas: txtComarcas,
        municipios: txtMunicipios,
        zonas: txtZonas,
      }
    },
  },
  watch: {
    localization: {
      handler() {
        this.$emit('updateValueLocalization', this.getDataSaveForm())
      },
      deep: true,
    },
  },
  async created() {
    try {
      this.loader.comunidade = true
      await store.dispatch('localizationMultiple/getAllComunidades').then(() => {
        this.loader.comunidade = false
      }).catch(error => {
        this.showMsgErrorRequest(error)
        this.loader.comunidade = false
      })
    } catch (err) {
      //
    }
  },
  methods: {
    async getProvinciasByComunidade() {
      const self = this

      this.$store.commit('localizationMultiple/setProvincias', [])
      this.localization.provincia = []

      this.$store.commit('localizationMultiple/setComarcas', [])
      this.localization.comarca = []

      this.$store.commit('localizationMultiple/setMunicipios', [])
      this.localization.municipio = []

      this.$store.commit('localizationMultiple/setZonas', [])
      this.localization.zona = []

      try {
        if (this.localization.comunidade) {
          self.loader.provincia = true
          await store.dispatch('localizationMultiple/getAllProvinciasByComunidade', {
            level1: this.localization.comunidade,
          }).then(() => {
            self.loader.provincia = false
          }).catch(error => {
            self.loader.provincia = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getComarcaByProvincia() {
      const self = this

      this.$store.commit('localizationMultiple/setComarcas', [])
      this.localization.comarca = []

      this.$store.commit('localizationMultiple/setMunicipios', [])
      this.localization.municipio = []

      this.$store.commit('localizationMultiple/setZonas', [])
      this.localization.zona = []

      try {
        if (this.localization.comunidade && this.localization.provincia) {
          self.loader.comarca = true
          await store.dispatch('localizationMultiple/getAllComarcasByProvincia', {
            level1: this.localization.comunidade,
            level2: this.localization.provincia,
          }).then(() => {
            self.loader.comarca = false
          }).catch(error => {
            self.loader.comarca = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getMunicipiosByComarca() {
      const self = this

      this.$store.commit('localizationMultiple/setMunicipios', [])
      this.localization.municipio = []

      this.$store.commit('localizationMultiple/setZonas', [])
      this.localization.zona = []

      try {
        if (this.localization.comunidade && this.localization.provincia && this.localization.comarca) {
          self.loader.municipio = true
          await store.dispatch('localizationMultiple/getAllMunicipiosByComarca', {
            level1: this.localization.comunidade,
            level2: this.localization.provincia,
            level3: this.localization.comarca,
          }).then(() => {
            self.loader.municipio = false
          }).catch(error => {
            self.loader.municipio = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    async getZonasByMunicipio() {
      const self = this

      this.$store.commit('localizationMultiple/setZonas', [])
      this.localization.zona = []

      try {
        if (this.localization.comunidade && this.localization.provincia && this.localization.comarca && this.localization.municipio) {
          self.loader.zona = true
          await store.dispatch('localizationMultiple/getAllZonasByMunicipio', {
            level1: this.localization.comunidade,
            level2: this.localization.provincia,
            level3: this.localization.comarca,
            level4: this.localization.municipio,
          }).then(() => {
            self.loader.zona = false
            store.commit('listings/setMunicipios', this.localization.municipio)
          }).catch(error => {
            self.loader.zona = false
            self.showMsgErrorRequest(error)
          })
        }
      } catch (err) {
        //
      }
    },
    clearForm() {
      this.localization.comunidade = []

      store.commit('localizationMultiple/setProvincias', [])
      this.localization.provincia = []

      store.commit('localizationMultiple/setComarcas', [])
      this.localization.comarca = []

      store.commit('localizationMultiple/setMunicipios', [])
      this.localization.municipio = []

      store.commit('localizationMultiple/setZonas', [])
      this.localization.zona = []
    },
    async getDataSaveForm() {
      const request = await new Promise(resolve => {
        const aLevel1 = []
        const aLevel2 = []
        const aLevel3 = []
        const aLevel4 = []
        const aLevel5 = []

        try {
          if (this.localization.comunidade.length > 0) {
            this.localization.comunidade.forEach(o => {
              aLevel1.push(o.id)
            })
          }

          if (this.localization.provincia.length > 0) {
            this.localization.provincia.forEach(o => {
              aLevel2.push(o.id)
            })
          }

          if (this.localization.comarca.length > 0) {
            this.localization.comarca.forEach(o => {
              aLevel3.push(o.id)
            })
          }

          if (this.localization.municipio.length > 0) {
            this.localization.municipio.forEach(o => {
              aLevel4.push(o.id)
            })
          }

          if (this.localization.zona.length > 0) {
            this.localization.zona.forEach(o => {
              aLevel5.push(o.id)
            })
          }
        } catch (err) {
        //
        }

        resolve({
          level1: aLevel1,
          level2: aLevel2,
          level3: aLevel3,
          level4: aLevel4,
          level5: aLevel5,
        })
      })

      return request
    },
    async loadDataForm(payload) {
      return new Promise(resolve => {
        (async () => {
          this.loader.comunidade = true
          await store.dispatch('localizationMultiple/getAllComunidades').then(() => {
            this.loader.comunidade = false
          }).catch(error => {
            this.showMsgErrorRequest(error)
            this.loader.comunidade = false
          })

          if (Array.isArray(payload.level1) && (payload.level1.length > 0)) {
            payload.level1.forEach(val => {
              const newItem = this.comunidades.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.comunidade.push(newItem)
              }
            })

            await this.getProvinciasByComunidade(this.localization.comunidade)
          }

          if (Array.isArray(payload.level2) && (payload.level2.length > 0)) {
            payload.level2.forEach(val => {
              const newItem = this.provinciasGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.provincia.push(newItem)
              }
            })

            await this.getComarcaByProvincia(this.localization.provincia)
          }

          if (Array.isArray(payload.level3) && (payload.level3.length > 0)) {
            payload.level3.forEach(val => {
              const newItem = this.comarcasGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.comarca.push(newItem)
              }
            })

            await this.getMunicipiosByComarca(this.localization.provincia)
          }

          if (Array.isArray(payload.level4) && (payload.level4.length > 0)) {
            payload.level4.forEach(val => {
              const newItem = this.municipiosGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.municipio.push(newItem)
              }
            })

            await this.getZonasByMunicipio(this.localization.municipio)
          }

          if (Array.isArray(payload.level5) && (payload.level5.length > 0)) {
            payload.level5.forEach(val => {
              const newItem = this.zonasGroup.find(o => Number(o.id) === Number(val))
              if (newItem !== undefined) {
                this.localization.zona.push(newItem)
              }
            })
          }

          await this.$nextTick()

          resolve(true)
        })()
      })
    },
  },
  setup() {
    const LOCALIZATION_MODULE_NAME = 'localizationMultiple'

    if (!store.hasModule(LOCALIZATION_MODULE_NAME)) {
      store.registerModule(LOCALIZATION_MODULE_NAME, localizationModule)

      onUnmounted(() => {
        if (store.hasModule(LOCALIZATION_MODULE_NAME)) store.unregisterModule(LOCALIZATION_MODULE_NAME)
      })
    }

    return {
    }
  },
}
</script>
