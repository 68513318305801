var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Comunidades / Cidades Autónomas'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.comunidades,"append-to-body":"","calculate-position":_vm.withPopper,"multiple":true,"loading":_vm.loader.comunidade,"label":"desc","item-text":"desc","item-value":"id"},on:{"input":_vm.getProvinciasByComunidade,"change":_vm.getProvinciasByComunidade},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var desc = ref.desc;
return [_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.comunidade),callback:function ($$v) {_vm.$set(_vm.localization, "comunidade", $$v)},expression:"localization.comunidade"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma comunidade / cidade autónoma'))+" ")])]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Províncias'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.provinciasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.provincia,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getComarcaByProvincia,"change":_vm.getComarcaByProvincia},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Províncias de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.provincia),callback:function ($$v) {_vm.$set(_vm.localization, "provincia", $$v)},expression:"localization.provincia"}},[(_vm.localization.comunidade)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma província'))+" ")]):_vm._e()]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Comarcas'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.comarcasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.comarca,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getMunicipiosByComarca,"change":_vm.getMunicipiosByComarca},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Comarcas de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.comarca),callback:function ($$v) {_vm.$set(_vm.localization, "comarca", $$v)},expression:"localization.comarca"}},[(_vm.localization.provincia)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma comarca'))+" ")]):_vm._e()]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Municípios'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.municipiosGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.municipio,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},on:{"input":_vm.getZonasByMunicipio,"change":_vm.getZonasByMunicipio},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Municípios de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.municipio),callback:function ($$v) {_vm.$set(_vm.localization, "municipio", $$v)},expression:"localization.municipio"}},[(_vm.localization.comarca)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhum município'))+" ")]):_vm._e()]),_c('h6',{staticClass:"section-label mt-3 mb-1 px-2"},[_vm._v(" "+_vm._s(_vm.$t('Zonas'))+" ")]),_c('v-select',{staticClass:"mb-1 ml-2 mr-2",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.zonasGroup,"append-to-body":"","calculate-position":_vm.withPopperGroup,"multiple":true,"loading":_vm.loader.zona,"label":"descFull","item-text":"descFull","item-value":"id","selectable":function (option) { return option.group === null; }},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var group = ref.group;
var desc = ref.desc;
return [(group)?_c('div',{staticClass:"group"},[_vm._v(" "+_vm._s(_vm.$t('Zonas de'))+" "+_vm._s(group)+" ")]):_vm._e(),_vm._v(" "+_vm._s(desc)+" ")]}}]),model:{value:(_vm.localization.zona),callback:function ($$v) {_vm.$set(_vm.localization, "zona", $$v)},expression:"localization.zona"}},[(_vm.localization.municipio)?_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(" "+_vm._s(_vm.$t('Nenhuma zona'))+" ")]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }